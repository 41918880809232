<!--
 * @Description: 数据表格(左侧树右侧列表) 右侧来源可能为配置页面@luocheng或者@曾伟固定页面
 * @Author: luocheng
 * @Date: 2021-12-20 10:08:54
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2023-06-16 10:38:05
-->
<template>
  <article class="subitem-table-data" v-loading="loading" style="display: flex">
    <div
      v-show="icon"
      style="
        width: 300px;
        height: 100%;
        display: flex;
        box-sizing: border-box;
        min-width: 300px;
      "
      v-if="showProjectListBool"
    >
      <SubitemTree
        v-if="treeProjectArray && Array.isArray(treeProjectArray)"
        :treeData="treeProjectArray"
        :defaultSelect="true"
        :withFilter="true"
        v-model="selectProjectKey"
        :treeProps="treeProjectProps"
        @setNodes="getProjectChildrenNode"
        treeTitle="项目列表"
        :isSubitem="false"
        default-expand-all
        v-loading="loading"
      >
      </SubitemTree>
    </div>
    <div
      v-if="treeProjectArray && Array.isArray(treeProjectArray) && showProjectListBool"
      style="position: relative"
    >
      <i
        class="iconfont shrink-icon iconjijia_shouqi"
        style="
          position: absolute;
          right: -10px;
          font-size: 24px;
          top: 15px;
          animation: fade 600ms infinite;
        "
        @click="clickIcon"
        :class="icon ? 'icon-all' : 'icon-hide'"
      />
    </div>
    <Splitpanes
      class="default-theme table-box"
      style="height: 100%; width: 100%; overflow-x: auto"
      @resize="onSplitpane"
      :push-other-panes="false"
    >
      <Pane id="left-pane" v-loading="showFormatTreeDefaultExpandKeysLoading" :size="leftSize" min-size="15" max-size="50">
        <SubitemTree
          v-if="treeData && Array.isArray(treeData)"
          :treeData="treeData"
          :defaultSelect="true"
          :withFilter="true"
          v-model="selectKeys"
          :treeProps="treeProps"
          @setNodes="getNodes"
          :defaultExpandKeys="defaultExpandKeysArray"
          :defaultExpandAll="defaultExpandAll"
          treeTitle="工程列表"
        ></SubitemTree>
      </Pane>
      <Pane :size="100 - leftSize" id="content-pane">
        <article class="table-content">
          <template v-if="pageId && !updating">
            <!-- 绑定的表单关系 -->
            <!-- <FromDataList
							:moduleUuid="pageId"
							v-if="pageType === 'formPage' && pageId"
							:formFillData="formFillData"
						></FromDataList>
						绑定的自定义配置页面 -->
            <CustomPage v-if="pageId && showPage" :pageUUID="pageId"> </CustomPage>
          </template>
          <el-empty v-else :description="emptyDesc"></el-empty>
        </article>
      </Pane>
    </Splitpanes>
  </article>
</template>

<script>
import SubitemTree from "@/components/subitem/SubitemTree.vue";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import { dataInterface } from "@/apis/data";
// import FromDataList from './formDataList/index';
import { Empty } from "element-ui";
import CustomPage from "@/views/Index";

//
// 新增三端@蒲亚军
// 逻辑: 若存在子项目，则根据项目获取分部分项；否则根据当前架构获取数据
//

const treeProjectProps = {
  children: "projects",
  label: "name",
};
export default {
  name: "SubitemTableData",
  components: {
    SubitemTree,
    Splitpanes,
    Pane,
    // FromDataList,
    CustomPage,
    "el-empty": Empty,
  },
  data() {
    return {
      treeProjectProps,
      treeProjectArray: null,
      selectProjectKey: "",
      showProjectListBool: false,
      loading: true,
      leftSize: 28,
      // 树形图
      treeData: null,
      selectKeys: [],
      treeProps: {
        children: "table_childrens",
        label: "name",
      },
      // 表单绑定的UUID
      moduleUuid: "",
      emptyDesc: "请选择分部分项",
      // 绑定的id 表单ID (formModule61c03a774f3ef)或页面uuid (page61937793eda7f)
      pageId: "",
      // pageType: '',
      // 虚线描述字段
      ancestorField: {
        industry_type: "I", // 行业类型
        version: "V", // 版本库
        project_type: "P", // 项目类型
        unit_engineering: "D", // 单位工程
        subunit_engineering: "ZD", // 子单位工程
        division_of_engineering: "B", // 分部工程
        subdivision_project: "ZB", // 子分部工程
        sectional_works: "X", // 分项工程
        sub_projects: "ZX", // 子分项工程
        inspection_lot: "J", // 检验批
        classification: "C", // 仅分类
        table: "T", // 表格
      },
      // 暴露的数据
      formFillData: {},
      // 类型
      tableType: "",
      TYPE_DESC: {
        supervision: "监理表格",
        quality: "质量表格",
        "side-station": "旁站表格",
        construction: "施工表格",
        parallel: "平行检验表格",
      },
      // 更新中(重新渲染)
      updating: false,
      showPage: false,
      // 接口数据对象
      interfaceConfig: {},
      // 三端选中的架构
      projectDetails: null,
      // 当前架构是否包含子项目
      hasChildren: 0,
      icon: true,
      defaultExpandKeysArray: [],
      preExpandKeysArray : [],
      showFormatTreeDefaultExpandKeysLoading: false,
      defaultExpandAll: false,
    };
  },
  created() {
    // this.getTreeData();
    if (this.$route.query && this.$route.query.id) {
      this.selectKeys.push(+this.$route.query.id);
      this.pageId = this.$route.query.list_uuid || this.$route.query.origin_list_uuid;
    }
    this.hasChildren = this.$GetTargetArchi("exist_children");
  },
  watch: {
    $route: {
      handler(newRoute, oldRoute) {
        if (oldRoute && oldRoute.name === newRoute.name) return;
        const arr = newRoute && newRoute.path.split("subitem-data-table/");
        if (!arr || arr.length < 2) {
          return;
        }
        this.tableType = this.TYPE_DESC[arr[1]];
        if (["supervision", "construction"].includes(arr[1])) {
          // 表格 视图缺失暂不改动
          this.interfaceConfig = {
            // object_uuid: 'object61a8855060394',
            // view_uuid: 'view61c52ed5f0323'
            object_uuid: "object62830dfd61652",
            view_uuid: "view62845e89b1dfa",
          };
        } else {
          // 分部分项
          this.interfaceConfig = {
            object_uuid: "object61a8855060394",
            // view6358a5f7dfc9a
            view_uuid: "view61c52ed5f0323",
          };
        }
        if (!this.tableType) {
          this.$message.error("出错了!");
          return;
        }
        if (arr[1] === "quality") {
          // 质量暂时处理先异步取前几层数据
          this._showProjectList(arr[1], { view_uuid: "view6358a5f7dfc9a" });
        }
        this._showProjectList(arr[1]);
        this.resetData(true);
        this.initPaneWidth();
      },
      deep: true,
      immediate: true,
    },
    pageId() {
      this.showPage = false;
      setTimeout(() => {
        this.showPage = true;
      }, 300);
    },
  },
  mounted() {
    this.initPaneWidth();
  },
  methods: {
    /**
     * @description: 修改分栏宽度@蒋冬梅
     */
    initPaneWidth() {
      this.$nextTick(() => {
        const leftPane = document.querySelector("#left-pane");
        const contentPane = document.querySelector("#content-pane");
        if (leftPane && contentPane) {
          leftPane.style.width = "265px";
          contentPane.style.width = "calc(100% - 265px)";
        }
      });
    },
    /**
     *
     * @param {*} array 需要查询的数组
     * @param {*} keyLabel 需要查询的数组keys,当keys为空时则默认查找叶子节点
     */
    findKeysToDefaultExpandTree(array = [], keyLabel = []) {
      let result = [];
      const _isNotLeafNode = (node, value) => {
        return Boolean(node[value]?.length);
      };
      const _useKeyFindItem = (arr, k) => {
        for (let i = 0; i < arr.length; i++) {
          let obj = arr[i];
          if (_isNotLeafNode(obj, k)) {
            _useKeyFindItem(obj[k], k);
          }
          if (keyLabel.length > 0 && keyLabel.includes(obj.type)) {
            result.push(obj.id);
          }
          if (keyLabel.length == 0) {
            result.push(obj.id);
          }
        }
      };
      _useKeyFindItem(array, this.treeProps.children);
      return result;
    },
    clickIcon() {
      this.icon = !this.icon;
      this.onSplitpane();
    },
    /**
     * @desc: 获取树形图(弃用)
     * @param {Object} view_uuid  {view_uuid : ""} 用于覆盖请求的uuid
     */
    getTreeData(view_uuid = {}, param ='') {
      if (!this.tableType) {
        this.$message.error("出错了!");
        return;
      }
      this.loading = true;
      return dataInterface({
        __method_name__: "dataList",
        ...this.interfaceConfig,
        // object_uuid: 'object61a8855060394',
        // view_uuid: 'view61c52ed5f0323',
        ...view_uuid,
        archi_type: this.$GetTargetArchi("data_id"),
        transcode: 0,
        table_type: this.tableType,
      })
        .then((res) => {
          if (res.status === 200) {
            this.showFormatTreeDefaultExpandKeysLoading = false;
            let treeData = res.data.data || [];
            
            // 如果是手动覆盖的预请求
            if(view_uuid.view_uuid !== undefined){
              // 如果 treeData 已经有数据了，直接丢出去
              if(this.treeData){
                return this.treeData; 
              }
              treeData = this._changePreChildren(treeData);
              this.treeData = this.formatParent(treeData, {});
            }else{
              this.$nextTick(() => {
                this.treeData = this.formatParent(treeData, {});
              })
            }
            console.log(treeData,'this.treeData solved')
            // 平行检验展开到部位
            if ( param === "parallel") {
              const arr = this.findKeysToDefaultExpandTree(treeData, ["单位工程", "子单位工程"]);
              this.defaultExpandKeysArray = [...arr,...this.preExpandKeysArray];
              // this.preExpandKeysArray = [];
            }
            this.loading = false;
            return this.treeData;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err, "9999999999");
          this.loading = false;
        });
    },

    /**
     * @description: 
     * @param {Object} treeData
     * @param {String} label
     * @return {Object} 转换成正常的数据
     */
    _changePreChildren(treeData,label = "children_5"){
      return treeData.map(ele => {
        if(ele[label] !== undefined && ele[label].length){
          ele[label] = this._changePreChildren(ele[label]);
        }
        ele[this.treeProps.children] = ele[label]
        return ele;
      })
    },

    /**
     * @desc: 寻找属性图的所有祖先字段
     * @param {Array} treeData 树形图
     * @param {Object} ancestor 祖先对象
     * @return {Object} 包含所有祖先的字段
     */
    formatParent(treeData, ancestor = {}) {
      return treeData.map((ele) => {
        const formFillData = {
          ...ancestor,
          ...this.getAncestorKey(ancestor, ele),
        };
        if (ele[this.treeProps.children] && ele[this.treeProps.children].length) {
          return {
            ...ele,
            formFillData,
            [this.treeProps.children]: this.formatParent(
              ele[this.treeProps.children],
              formFillData
            ),
          };
        }
        return {
          ...ele,
          formFillData,
        };
      });
    },
    /**
     * @desc: 获取
     * @param {Object} ancestor 祖先
     * @param {Object} node 当前节点
     */
    getAncestorKey(ancestor, node) {
      const keys = Object.keys(ancestor);
      const {
        id,
        name,
        type,
        type_id,
        acceptance_items,
        origin_data = [],
        inspection_type,
        construction_specifications,
        specification = "",
      } = node;
      let targetKey = this.ancestorField[type_id];
      let count = 0;
      keys.forEach((ele) => {
        if (ele.toString().indexOf(targetKey) > -1) {
          count++;
        }
      });
      if (count > 0) {
        targetKey += `_${count}`;
      }
      return {
        ...ancestor,
        [targetKey + "_origin_id"]:
          origin_data && origin_data.length ? origin_data[0].id : null,
        [targetKey + "_id"]: id,
        [targetKey + "_name"]: name,
        [targetKey + "_type"]: type,
        [targetKey + "_type_id"]: type_id,
        [targetKey + "_acceptance_name"]:
          acceptance_items && acceptance_items.length
            ? acceptance_items[0].specification
            : specification || "",
        [targetKey + "_inspection_type"]:
          type_id === "inspection_lot" ? inspection_type || 1 : "",
        [targetKey + "_construction_specifications"]: construction_specifications,
        // [targetKey + '_children']: node[this.treeProps.children] || []
      };
    },
    /**
     * @desc: 获取树形图选中的节点
     * @param {Array} data 选中的节点ID
     */
    getNodes(details) {
      this.preExpandKeysArray.push(details.id)
      this.resetData(false);
      this.updating = true;
      // console.log(details, '----details----');
			// 取消表格类型限制 @蒋冬梅
      // if (details.type_id !== "table") {
      //   this.emptyDesc = "暂无有效数据！";
      //   this.updating = false;
      //   return false;
      // }
      if (!details) {
        this.loading = false;
        this.emptyDesc = "获取数据失败！";
        // this.updating = false;
        return;
      }
      let pageId = details.list_uuid || details.origin_list_uuid || "";
      if (!pageId && details.origin_data) {
        const { origin_data } = details;
        if (Array.isArray(origin_data) && origin_data.length) {
          pageId = origin_data[0].list_uuid || "";
        }
      }
      let originDta = null;
      if (typeof details.origin_data === "object" && details.origin_data.length) {
        originDta = details.origin_data[0];
      }
      if (typeof details.formFillData === "object") {
        sessionStorage.setItem("_formFilleData", JSON.stringify(details.formFillData));
      }
      if (!pageId) {
        this.emptyDesc = "暂无绑定数据！";
        // this.updating = false;
        return;
      }
      this.pageId = pageId;
      this.$router.replace({
        path: this.$route.path,
        query: {
          id: details.id,
          type_id: details.type_id,
          list_uuid: details.list_uuid,
          // module_uuid: details.module_uuid,
          origin_id: originDta?.id,
          origin_list_uuid: originDta?.list_uuid,
          // origin_module_uuid: originDta?.module_uuid
        },
      });
      this.$nextTick(() => {
        this.updating = false;
      });
    },
    /**
     * @desc: 重置数据
     */
    resetData(resetTree) {
      // 表单绑定的UUID
      this.moduleUuid = "";
      this.emptyDesc = "请选择分部分项";
      this.pageId = "";
      // this.pageType = '';
      if (resetTree) {
        this.treeData = null;
        this.selectKeys = [];
        if (+this.$GetTargetArchi("exist_children")) {
          this.getProjectChildrenNode(null);
        } else {
          this.getTreeData();
        }
      }
    },
    /**
     * 根据架构id获取架构下的所有项目
     */
    _getProjectsList() {
      let id = this.$GetUserInfo("id");
      if (!id) {
        this.$message.error("出错了!");
        return;
      }
      this.loading = true;
      return dataInterface({
        __method_name__: "dataList",
        object_uuid: "object616fe6fe8119f",
        view_uuid: "view62986db9582aa", // view61b1d47593ddd
        transcode: 0,
        id: id,
      })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            this.showFormatTreeDefaultExpandKeysLoading = false;
            const data = res.data.data || {};
            if (Array.isArray(data)) {
              this.treeProjectArray = data;
            } else {
              this.treeProjectArray = data?.data || [];
            }
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          throw new Error(err);
        });
    },

    /**
     * @description: 点击项目列表中的item条目时触发 获取该项目下的分项目类
     * @param {*} details
     * @return {*}
     */
    getProjectChildrenNode(details = null) {
      this.showFormatTreeDefaultExpandKeysLoading = true;
      if (details) {
        this.projectDetails = details;
      }
      if (!this.projectDetails) return;
      if (!this.tableType) {
        this.$message.error("出错了!");
        return;
      }
      // this.loading = true;
      console.log(details, "-----details-----");
      // let projectId = details.id
      dataInterface({
        __method_name__: "dataList",
        object_uuid: "object61a8855060394",
        view_uuid: "view61c52ed5f0323",
        archi_type: this.projectDetails.archi_type_value,
        transcode: 0,
        table_type: this.tableType, // 表格类型
        // id: projectId,
      })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            const data = res.data.data || [];
            this.showFormatTreeDefaultExpandKeysLoading = false;
            this.treeData = data;
            const expendList = this.findKeysToDefaultExpandTree(data, ["单位工程", "子单位工程"]);
            this.defaultExpandKeysArray = expendList;
            return data;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.error(`TableDataERRoR:--${err}`);
        });
    },
    /**
     * @description: 用于显示左侧项目列表
     * @param {*} param String类型
     * @return {*}
     */
    _showProjectList(param, view_uuid = {}) {
      this.showFormatTreeDefaultExpandKeysLoading = true;
      this.defaultExpandAll = false;
      let promise = null;
      let arr = ["quality", "parallel"]; // 此数组参数是依赖于 this.data.TYPE_DESC中的key值,数组存在该值则显示项目列表
      if (arr.includes(param) && +this.$GetTargetArchi("exist_children")) {
        this.showProjectListBool = true;
        promise = this._getProjectsList();
      } else {
        this.showProjectListBool = false;
        promise = this.getTreeData(view_uuid, param);
        // 监理管理默认全部展开
        if (param === "supervision" || param === "construction") {
          this.defaultExpandAll = true;
        }
      }
      if (!promise) {
        this.showFormatTreeDefaultExpandKeysLoading = false;
      }
      promise
        ?.then((r) => {
          // this.showFormatTreeDefaultExpandKeysLoading = false;
          if (r) {
            let arr = [];
            let needUnfoldTreeToLeafNodeArray = ["supervision", "construction"];
            if (needUnfoldTreeToLeafNodeArray.includes(param)) {
              arr = this.findKeysToDefaultExpandTree(r, []);
            } else {
              arr = this.findKeysToDefaultExpandTree(r, ["单位工程", "子单位工程"]);
            }
            this.defaultExpandKeysArray = [...arr,...this.preExpandKeysArray];
            this.preExpandKeysArray = [];
          }
        })
        .catch((err) => {
          console.log(err, "---123123---");
          this.showFormatTreeDefaultExpandKeysLoading = false;
        });
    },
    /**
     * @desc: 拖动分隔
     */
    onSplitpane() {
      console.log("拖动分隔-----");
      try {
        const ev = document.createEvent("Event");
        ev.initEvent("resize", true, true);
        window.dispatchEvent(ev);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.subitem-table-data {
  height: calc(100vh - 130px);
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding: 10px;

  .table-content {
    position: relative;
    box-sizing: border-box;
    padding: 0 10px;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .el-empty {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }
}

.icon-hide {
  transform: rotateY(180deg);
}

:deep(.splitpanes--vertical > .splitpanes__splitter) {
  &:before {
    width: 4px;
    height: 50px;
  }

  &:after {
    width: 4px;
    height: 50px;
  }
}
</style>
